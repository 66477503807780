import { IconBellRing } from '@troon/icons';
import { Button, Dialog, DialogTrigger } from '@troon/ui';
import { Match, Switch, createSignal } from 'solid-js';
import { useUser } from '../../../../../../providers/user';
import { AuthFlow } from '../../../../../../partials/auth/auth';
import { CreateTeeTimeAlert } from './create-tee-time-alert';
import { TeeTimeAlertPushNotifications } from './tee-time-push-notifications';
import type { ComponentProps } from 'solid-js';

type Props = Omit<ComponentProps<typeof CreateTeeTimeAlert>, 'onClose' | 'onShowAppDownload'>;

export function TeeTimeAlertPrompt(props: Props) {
	return (
		<div class="flex flex-row justify-stretch gap-4">
			<IconBellRing class="size-full min-w-8 max-w-24 shrink grow basis-1/12 text-brand" />
			<div class="flex flex-col items-start gap-4">
				<h3 class="text-lg font-semibold">Not seeing the tee time you want?</h3>
				<p>Get an alert if a tee time becomes available based on your preferences.</p>
				<DialogTrigger>
					<Button appearance="secondary">Set alerts</Button>
					<TeeTimeAlertDialog {...props} />
				</DialogTrigger>
			</div>
		</div>
	);
}

export function TeeTimeAlertDialog(props: Props) {
	const [showAppDownload, setShowAppDownload] = createSignal(false);
	const user = useUser();

	return (
		<Dialog
			header={
				showAppDownload() ? 'Download the Troon Golf app!' : user() ? 'Set tee time alert' : 'Log in to set alerts'
			}
			headerLevel="h4"
			key="tee-time-alert-upsell"
			onClose={() => {
				setShowAppDownload(false);
			}}
		>
			{(handleClose) => (
				<Switch>
					<Match when={!user()}>
						<AuthFlow />
					</Match>
					<Match when={!showAppDownload()}>
						<CreateTeeTimeAlert onClose={handleClose} onShowAppDownload={() => setShowAppDownload(true)} {...props} />
					</Match>
					<Match when={showAppDownload()}>
						<TeeTimeAlertPushNotifications />
					</Match>
				</Switch>
			)}
		</Dialog>
	);
}
