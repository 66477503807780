import { Link } from '@troon/ui';

export function TeeTimeAlertPushNotifications() {
	return (
		<>
			<div class="flex flex-col items-center gap-6 px-8 text-center">
				<img
					src="/assets/images/qr-app-store.svg"
					alt="QR Code to download the Troon Golf app on the Apple App Store"
					class="hidden max-w-64 sm:block"
					width="256px"
					height="256px"
				/>
				<p class="hidden text-lg font-semibold sm:block">Scan the QR code to download Troon Golf</p>
				<p class="block text-lg font-semibold sm:hidden">Download the Troon Golf app now</p>
				<p>Don't miss out on your tee time alerts. Download the Troon Golf app today.</p>
				<Link href="/app" target="_blank">
					<img src="/assets/images/app-store.png" alt="Available on the Apple App Store" width="135px" height="40px" />
				</Link>
			</div>
		</>
	);
}
